import { Link } from 'gatsby';
import React, { useState } from 'react';
import { isMobile } from 'mobile-device-detect';

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header>
      <div className='flex flex-wrap items-center justify-between md:justify-start p-3 mx-auto md:p-4 text-gray-900'>
        <div className='z-50'>
          <Link to='/'>
            <h1 className='flex items-center  text-2xl no-underline'>
              <span className=' font-serif uppercase font-bold '>Testing </span>
              <span className=' font-bold uppercase'> Faith</span>
            </h1>
          </Link>
        </div>
        <button
          className='items-center block px-3 py-2 text-gray-900 md:hidden z-50'
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className='w-6 h-6 fill-current'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded
              ? `flex flex-col space-y-16 items-center justify-center bg-white fixed left-0 bottom-0 w-full h-screen z-20  pb-8  nav-expanded`
              : `hidden`
          }  md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/`,
              title: `Home`,
            },
            {
              route: `/diary`,
              title: `Faith's Diary`,
            },
            {
              route: `/learn-about-project`,
              title: `Learn More`,
            },
            {
              route: `/share-faiths-story`,
              title: `Share Faith's Story`,
            },
          ].map((link) => (
            <Link
              className={`${
                isExpanded ? `` : ``
              } block mt-4 no-underline md:inline-block md:mt-0 md:ml-6 text-2xl md:text-xl`}
              key={link.title}
              to={link.route}
              onClick={isMobile ? () => toggleExpansion(!isExpanded) : null}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
